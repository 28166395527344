// Base imports
import { useEffect, lazy } from 'react';
import { Switch, withRouter, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './index.css';

// Utils and extras
import ErrorSnackbar from 'components/error-snackbar/error-snackbar';
import { ErrorProvider } from 'components/error-snackbar/error-snackbar-provider';
import { ShoppingCartProvider } from 'components/shopping-cart/shopping-cart-provider';
import TagManager from 'react-gtm-module-custom-domain';
import { makeStyles, MuiThemeProvider } from '@material-ui/core';
import ScrollToTop from 'components/scroll-to-top/scroll-to-top';
import theme from 'components/theme';
import MainLayout from 'components/layouts/main-layout';
import AllBlue from 'components/layouts/all-blue';
import PaymentLayout from 'components/layouts/payment-layout';
import TailwindLayout from 'components/layouts/tailwind-layout';
import EmptyLayout from 'components/layouts/empty-layout';
import AppRoute from 'routes/app-route';
import URLShortenerRedirect from 'pages/url-shortener-redirect/url-shortener-redirect';
import { encodeQueryString, getBrandInfo } from 'utils/utils';
import suspenseWrapper from 'components/additional-contact/suspense-wrapper';
import FemmeFullGrey from 'components/layouts/femme-full-grey';
import { ProductContextProvider } from 'components/product-context-provider';
import useMPID from 'hooks/use-mpid';

// Views
const GenericNotFound = lazy(() =>
  import(
    /* webpackChunkName: "genericNotFound" */ 'pages/errors/unauthorized-error'
  )
);
const GenericErrorPage = lazy(() =>
  import(
    /* webpackChunkName: "genericErrorPage" */ 'pages/errors/generic-error-page'
  )
);
const GenericPage = lazy(() =>
  import(/* webpackChunkName: "genericPage" */ 'pages/generic-page')
);

const CommunicationChangeConfirmation = lazy(() =>
  import(
    /* webpackChunkName: "verifyCommunicationChange" */ 'pages/communication-change-confirmation/communication-change-confirmation'
  )
);

const MIPRouter = lazy(() =>
  import(/* webpackChunkName: "MIPRouter" */ 'routes/mip-router')
);

const HormoneAssessment = lazy(() =>
  import(
    /* webpackChunkName: "hormoneAssessment" */ 'pages/mip/hrt/hormone-assessment/hormone-assessment'
  )
);
const FemaleHormoneAssessment = lazy(() =>
  import(
    /* webpackChunkName: "femaleHormoneAssessment" */ 'pages/mip/hrt/f-assessment/f-assessment-ghrt'
  )
);

const CalendlyFemale = lazy(() =>
  import(
    /* webpackChunkName: "femaleHormoneAssessment" */ 'pages/calendly/calendly-female'
  )
);
const FemaleIntake = lazy(() =>
  import(
    /* webpackChunkName: "femaleHormoneAssessment" */ 'pages/mip/hrt/f-intake/f-intake'
  )
);
const FemaleContinuous = lazy(() =>
  import(
    /* webpackChunkName: "femaleContinuous" */ 'pages/mip/continuous/chrt/female/f-chrt'
  )
);

const FemaleContinuousFinish = lazy(() =>
  import(
    /* webpackChunkName: "femaleContinuousFinish" */ 'pages/mip/continuous/chrt/chrt-finish'
  )
);

const HormoneAssessmentGHRT = lazy(() =>
  import(
    /* webpackChunkName: "hormoneAssessmentGHRT" */ 'pages/mip/hrt/hormone-assessment/hormone-assessment-ghrt'
  )
);

const HormoneAssessmentResults = lazy(() =>
  import(
    /* webpackChunkName: "assessmentResults" */ 'pages/mip/hrt/results/results'
  )
);

const FemaleHormoneAssessmentResults = lazy(() =>
  import(
    /* webpackChunkName: "femaleAssessmentResults" */ 'pages/mip/hrt/female-results/female-results'
  )
);

const HormoneAssessmentPayment = lazy(() =>
  import(
    /* webpackChunkName: "assessmentPayment" */ 'pages/mip/hrt/payment/payment'
  )
);

const FemaleHRTPayment = lazy(() =>
  import(
    /* webpackChunkName: "femaleAssessmentPayment" */ 'pages/mip/hrt/f-payment/f-payment'
  )
);

const HormoneAssessmentThankYou = lazy(() =>
  import(
    /* webpackChunkName: "assessmentThankYou" */ 'pages/mip/hrt/video-page/thank-you'
  )
);

const CommerceInfoUpdate = lazy(() =>
  import(
    /* webpackChunkName: "commerceInfoUpdate" */ 'pages/commerce-info-update/commerce-info-update'
  )
);
const UniversalUpload = lazy(() =>
  import(
    /* webpackChunkName: "universalUpload" */ 'pages/universal-upload/universal-upload'
  )
);

const PostClinic = lazy(() =>
  import(/* webpackChunkName: "postClinic" */ 'pages/post-clinic/post-clinic')
);

const MemoAppointmentIframe = lazy(() =>
  import(
    /* webpackChunkName: "universalUpload" */ 'pages/memo/microclinic-appointment-iframe'
  )
);

const nonLandingIds: Array<string | null> = ['', '0'];

const useStyles = makeStyles(() => ({
  wrapper: {
    overflow: 'hidden',
    minHeight: '100%',
    boxSizing: 'border-box',
  },
}));

const { isFemme, isMale, brandName } = getBrandInfo();

function App() {
  useMPID();
  const classes = useStyles();
  const { location } = window;
  const queryParams = new URLSearchParams(location.search);

  const useTailwindLayout = location.pathname.includes('dailytada2');

  useEffect(() => {
    setTimeout(() => {
      if (isMale && process.env.REACT_APP_GTM_ID) {
        const GTM = process.env.REACT_APP_GTM_ID;
        TagManager.initialize({
          gtmId: GTM,
          customURL: 'https://sgtm.maleexcel.com/tg.js',
        });
      }
      if (isFemme && process.env.REACT_APP_FEM_GTM_ID) {
        const GTM = process.env.REACT_APP_FEM_GTM_ID;
        TagManager.initialize({
          gtmId: GTM,
          customURL: 'https://sgtm.femexcelle.com/tg.js', // leaving at old domain until further notice
        });
      }
    }, 3000);
  }, []);

  if (
    location.protocol !== 'https:' &&
    location.hostname !== 'localhost' &&
    process.env.NODE_ENV !== 'development'
  ) {
    window.location.href = window.location.href.replace('http://', 'https://');
  }
  if (location.pathname.slice(-1) === '/' && location.pathname.length > 1) {
    // removing the trailing slashes to preempt react-router from doing so when pushing to history.
    window.location.pathname = location.pathname.slice(0, -1);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.wrapper}>
        <Helmet>
          <title>{brandName}</title>
          <link
            rel='icon'
            type='image/png'
            href={
              isFemme
                ? '/femE_logo.png'
                : 'https://mxc-wp-upload.s3.us-east-2.amazonaws.com/wp-content/uploads/sites/2/2021/07/07074300/fav.png'
            }
            sizes='16x16'
          />
          <script
            async
            src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`}
          />
          <script src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`} />
        </Helmet>
        <ScrollToTop>
          <ErrorProvider>
            <ErrorSnackbar />
            <ShoppingCartProvider>
              <ProductContextProvider>
                <Switch>
                  {
                    // remove blank landing_id search params
                    nonLandingIds.includes(queryParams.get('landing_id')) && (
                      <Redirect
                        exact
                        from={location.pathname}
                        to={{
                          pathname: location.pathname,
                          search: encodeQueryString(
                            Object.fromEntries(
                              [...queryParams.entries()].filter(
                                ([key]) => key !== 'landing_id'
                              )
                            )
                          ),
                        }}
                      />
                    )
                  }
                  <AppRoute
                    path='/f/payment/:pathCode'
                    component={suspenseWrapper(FemaleHRTPayment)}
                    layout={MainLayout}
                    site='female'
                  />
                  <AppRoute
                    path='/f/hormone-assessment/:pathCode'
                    component={suspenseWrapper(FemaleHormoneAssessment)}
                    layout={MainLayout}
                    site='female'
                  />
                  <AppRoute
                    path='/f/intake/:pathCode'
                    component={suspenseWrapper(FemaleIntake)}
                    layout={MainLayout}
                    site='female'
                  />
                  <AppRoute
                    path='/f/hormone-assessment/:pathCode'
                    component={suspenseWrapper(FemaleHormoneAssessment)}
                    layout={MainLayout}
                    site='female'
                  />
                  <AppRoute
                    path='/f/appointment/:appointmentType/:pathCode/:pageId?'
                    component={suspenseWrapper(CalendlyFemale)}
                    layout={MainLayout}
                    site='female'
                  />
                  <AppRoute
                    path='/f/results/:pathCode'
                    component={suspenseWrapper(FemaleHormoneAssessmentResults)}
                    layout={FemmeFullGrey}
                    site='female'
                  />
                  <AppRoute
                    path='/f/payment/:pathCode'
                    component={suspenseWrapper(FemaleHRTPayment)}
                    layout={MainLayout}
                    site='female'
                  />
                  <AppRoute
                    path='/f/cont/:pathCode'
                    component={suspenseWrapper(FemaleContinuous)}
                    layout={MainLayout}
                    site='female'
                  />
                  <AppRoute
                    path='/f/conf/:pathCode/:finishType'
                    component={suspenseWrapper(FemaleContinuousFinish)}
                    layout={MainLayout}
                    site='female'
                  />
                  <Redirect
                    exact
                    from='/mip/form/CHRT'
                    to={{
                      pathname: '/mip/cont/chrt',
                      search: location.search,
                    }}
                  />
                  <Route path='/mip' component={suspenseWrapper(MIPRouter)} />
                  <Redirect
                    exact
                    from='/hormone-assessment/(hrt|shrt1|ihrt)?'
                    to={{
                      pathname: '/hormone-assessment/shrt',
                      search: location.search,
                    }}
                  />
                  {isMale && (
                    <Route path='/hormone-assessment/ghrt'>
                      <AppRoute
                        path='/hormone-assessment/:pathCode'
                        component={suspenseWrapper(HormoneAssessmentGHRT)}
                        layout={PaymentLayout}
                      />
                    </Route>
                  )}
                  {isMale && (
                    <AppRoute
                      path='/quiz'
                      component={suspenseWrapper(HormoneAssessmentGHRT)}
                      layout={PaymentLayout}
                    />
                  )}
                  <AppRoute
                    path='/hormone-assessment/:pathCode'
                    component={suspenseWrapper(HormoneAssessment)}
                    layout={MainLayout}
                    site='male'
                  />
                  <Redirect
                    exact
                    from='/results'
                    to={{
                      pathname: '/results/shrt',
                      search: location.search,
                    }}
                  />
                  <AppRoute
                    path='/results/:pathCode'
                    component={suspenseWrapper(HormoneAssessmentResults)}
                    layout={AllBlue}
                    site='male'
                  />
                  <Redirect
                    exact
                    from='/payment/(shrt1)?'
                    to={{
                      pathname: '/payment/shrt',
                      search: location.search,
                    }}
                  />
                  <AppRoute
                    path='/payment/:pathCode'
                    component={suspenseWrapper(HormoneAssessmentPayment)}
                    layout={PaymentLayout}
                    site='male'
                  />
                  <Redirect
                    exact
                    from='/thank-you/(shrt1|shrt2)?'
                    to={{
                      pathname: '/thank-you/shrt',
                      search: location.search,
                    }}
                  />
                  <AppRoute
                    path='/thank-you/:pathCode'
                    component={suspenseWrapper(HormoneAssessmentThankYou)}
                    layout={MainLayout}
                    site='male'
                  />
                  <AppRoute
                    path='/commerce-info-update'
                    component={suspenseWrapper(CommerceInfoUpdate)}
                    layout={MainLayout}
                    site='male'
                  />
                  <AppRoute
                    path='/upload/:uploadComp?'
                    component={suspenseWrapper(UniversalUpload)}
                    layout={useTailwindLayout ? TailwindLayout : MainLayout}
                    site='male'
                  />
                  <AppRoute
                    path='/finish/:pathCode'
                    component={suspenseWrapper(GenericPage)}
                    layout={MainLayout}
                  />
                  <AppRoute
                    path='/comm-verify/:pathCode'
                    component={suspenseWrapper(CommunicationChangeConfirmation)}
                    layout={MainLayout}
                  />
                  <AppRoute
                    path='/s/:pathCode'
                    component={URLShortenerRedirect}
                    layout={EmptyLayout}
                  />
                  <AppRoute
                    path='/post-clinic/:postClinicId'
                    component={PostClinic}
                    layout={MainLayout}
                  />
                  <AppRoute
                    path='/error/:code/:pathCode'
                    component={suspenseWrapper(GenericErrorPage)}
                    layout={MainLayout}
                  />
                  <AppRoute
                    path='/memo/appointment'
                    component={suspenseWrapper(MemoAppointmentIframe)}
                    layout={EmptyLayout}
                  />
                  <AppRoute
                    component={suspenseWrapper(GenericNotFound)}
                    layout={MainLayout}
                  />
                </Switch>
              </ProductContextProvider>
            </ShoppingCartProvider>
          </ErrorProvider>
        </ScrollToTop>
      </div>
    </MuiThemeProvider>
  );
}

export default withRouter(App);
