import { Box, Container, Grid, Link, Typography, useMediaQuery, makeStyles, Theme } from '@material-ui/core';
import { getBrandInfo } from 'utils/utils';
import { ReactComponent as ExcelMedical } from '../../../assets/icons/footer-mui/excel-medical-gray.svg';
import { ReactComponent as ExcelMedicalLabs } from '../../../assets/icons/footer-mui/excel-medical-labs-gray.svg';
import { ReactComponent as FemexcelLogo } from '../../../assets/icons/footer-mui/femexcel/fem-excel-color.svg';
import { ReactComponent as FemexcelGrayLogo } from '../../../assets/icons/footer-mui/femexcel/fem-excel-gray.svg';
import { ReactComponent as MaleExcelLogo } from '../../../assets/icons/footer-mui/maleexcel/male-excel-color.svg';
import { ReactComponent as MaleExcelGrayLogo } from '../../../assets/icons/footer-mui/maleexcel/male-excel-gray.svg';
import { ReactComponent as Facebook } from '../../../assets/icons/footer-mui/facebook.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/footer-mui/icon-instagram.svg';
import { ReactComponent as LinkedIn } from '../../../assets/icons/footer-mui/linkedin.svg';
import { ReactComponent as X } from '../../../assets/icons/footer-mui/twitter-new.svg';
import styles from './Footer.module.css';

const useStyles = makeStyles((theme) => ({
    topRowPadding: {
        paddingBottom: '12px',
        [theme.breakpoints.down('md')]: {
          paddingBottom: '20px',
        },
    },
    topRowPaddingFem: {
        paddingTop: '16px',
        paddingBottom: '16px',
        [theme.breakpoints.down('md')]: {
          paddingTop: '20px',
          paddingBottom: '20px'
        },
    },
    maxWidthXl: {
        maxWidth: '1536px'
    },
    maxWidthXl2: {
        maxWidth: '1504px'
    },
    maxWidthXl3: {
        maxWidth: '1488px'
    },
    maxWidthXl4: {
        maxWidth: '1392px'
    },
    marginAuto: {
        margin: '0 auto',
    },
    marginY: {
        marginTop: '1em',
        marginBottom: '1em',
    },
    marginX: {
        marginLeft: '1em',
        marginRight: '1em',
    },
    textCenter: {
        textAlign: 'center'
    },
    dynamicBox: {
        display: 'flex',
        justifyContent: 'center',
        height: '40px',
        [theme.breakpoints.down('md')]: {
          height: '24px',
        },
    },
    dynamicBox2: {
        display: 'flex',
        justifyContent: 'center',
        height: '50px',
        [theme.breakpoints.down('md')]: {
          height: '40px',
        },
    },
    responsiveMargin: {
        margin: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(0),
        },
    },
    paddingX: {
        padding: '0.5em'
    },
    paddingBottom: {
        padding: '20px'
    },
    paddingTop: {
        paddingTop: '3em'
    },
    order1and2: {
        order: 1,
        [theme.breakpoints.down('md')]: {
          order: 2,
        },
      },
    order2and1: {
        order: 2,
        [theme.breakpoints.down('md')]: {
          order: 1,
        },
    },
    order2and3: {
        order: 2,
        [theme.breakpoints.down('md')]: {
          order: 3,
        },
    },
    order3and3: {
        order: 3,
        [theme.breakpoints.down('md')]: {
          order: 3,
        },
    },
    order3and1: {
        order: 3,
        [theme.breakpoints.down('md')]: {
          order: 1,
        },
    },
    order4and4: {
        order: 4,
        [theme.breakpoints.down('md')]: {
          order: 4,
        },
    },
  }));

const { isMale } = getBrandInfo();

const FooterMUI = () => {
  const classes = useStyles();
  const wtProduct = isMale ? 'maleexcel' : 'femexcel';

  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const stackGap = '0.5em'

  return (
    <Box className={styles.container}>
      <Box className={`${styles.topRow} ${isMale ? classes.topRowPadding : classes.topRowPaddingFem} `} sx={{ py: 1 }}>
        <Grid className={`${classes.maxWidthXl} ${classes.marginAuto}`} container alignItems="baseline" justifyContent="center">
          <Grid
            item
            xl={3}
            lg={3}
            md={6}
            sm={6}
            xs={6}
            className={classes.order1and2}
          >
            <Link
              href="https://excelmedical.com/"
              className={classes.dynamicBox}

            >
              <ExcelMedical viewBox="30 20 728 100" />
            </Link>
          </Grid>
          {wtProduct === 'maleexcel' && (
            <Grid
              item
              xl={3}
              lg={3}
              md={12}
              sm={12}
              xs={12}
              className={`${classes.paddingX} ${classes.textCenter} ${classes.order2and1} ${
                isMediumScreen ? styles.maleexcelLogoContainer : ''
              }`}
            >
              <Link
                href="https://maleexcel.com/"
                className={`${classes.dynamicBox2} ${isMediumScreen ? styles.maleexcelLogo : null}
            `}
              >
                <MaleExcelLogo viewBox="52 20 672 100" />
              </Link>
            </Grid>
          )}
          {wtProduct === 'femexcel' && (
            <Grid
              item
              xl={3}
              lg={3}
              md={6}
              sm={6}
              xs={6}
              className={`${classes.textCenter} ${classes.order2and3}`}
            >
              <Link
                href="https://maleexcel.com/"
                className={classes.dynamicBox}
              >
                <MaleExcelGrayLogo viewBox="52 20 675 100" />
              </Link>
              {/* is this ok? */}
            </Grid>
          )}
          {wtProduct === 'maleexcel' && (
            <Grid
              item
              xl={3}
              lg={3}
              md={6}
              sm={6}
              xs={6}
              className={`${classes.textCenter} ${classes.order3and3}`}

            >
              <Link
                href="https://femexcel.com/"
                className={classes.dynamicBox}
              >
                <FemexcelGrayLogo viewBox="40 10 700 120" />
              </Link>
            </Grid>
          )}
          {wtProduct === 'femexcel' && (
            <Grid
              item
              xl={3}
              lg={3}
              md={12}
              sm={12}
              xs={12}
              className={`${classes.textCenter} ${classes.order3and1} ${isMediumScreen ? styles.femexcelLogoContainer : null}`}
            >
              <Link
                href="https://femexcel.com/"
                className={`${isMediumScreen ? styles.femexcelLogo : null} ${classes.dynamicBox2}`}
              >
                <FemexcelLogo viewBox="40 10 700 120" />
              </Link>
            </Grid>
          )}
          <Grid
            item
            xl={3}
            lg={3}
            md={12}
            sm={12}
            xs={12}
            className={`${classes.textCenter} ${classes.order4and4}`}
          >
            <Link
              href="https://excelmedicallabs.com/"
              className={`${classes.dynamicBox} ${classes.responsiveMargin}`}
            >
              <ExcelMedicalLabs viewBox="32 30 725 75" />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Container className={classes.maxWidthXl2}>
        <Grid
          container
          spacing={2}
          className={`${classes.paddingTop} ${wtProduct === 'maleexcel' ? styles.maleLinks : styles.femLinks}`}
        >
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={styles.box}>
            <Typography className={styles.footerTitle}>Contact Us</Typography>
            <Typography className={styles.hours}>Mon-Sun: 8am-8pm EST</Typography>
            <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: stackGap }}>
              <Typography className={styles.phone} component={Link} href="tel: +1 (833) 625-3392">
                Phone: (833) 625-3392 {wtProduct === 'femexcel' && '(ext 305)'}
              </Typography>
            </Box>
            {wtProduct === 'maleexcel' && (
              <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: stackGap }}>
                <Typography className={styles.sms} component={Link} href="sms: +1 (833) 394-7744">
                  Text: (833) 394-7744
                </Typography>
              </Box>
            )}
            <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: stackGap }}>
              <Typography className={styles.email} component={Link} href="mailto: support@maleexcel.com">
                support@{wtProduct === 'maleexcel' ? 'maleexcel.com' : 'femexcel.com'}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={styles.box}>
            {wtProduct === 'femexcel' && (
              <>
                <Typography className={styles.footerTitle}>About Us</Typography>
                <Link href="https://femexcel.com/#what-we-do">What We Do</Link>
                <Link href="https://femexcel.com/#what-we-treat">What We Treat</Link>
                <Link href="https://femexcel.com/#treatments">The Benefits</Link>
                <Link href="https://femexcel.com/#about-us">Why Fem Excel?</Link>
                <Link href="https://femexcel.com/#pricing">Pricing</Link>
              </>
            )}
            {wtProduct === 'maleexcel' && (
              <>
                <Typography className={styles.footerTitle}>Treatments</Typography>
                <Link href="https://maleexcel.com/treatments/trt-online/">Testosterone Replacement</Link>
                <Link href="https://maleexcel.com/treatments/bioidentical-thyroid/">Thyroid Therapy</Link>
                <Link href="https://maleexcel.com/treatments/male-hormone-test/">Testosterone Test Kit</Link>
                <Link href="https://maleexcel.com/treatments/hrt-costs/">Testosterone Treatment Pricing</Link>
                <Link href="https://maleexcel.com/ed-treatment/">ED Medications</Link>
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={styles.box}>
            {wtProduct === 'femexcel' && (
              <>
                <Typography className={styles.footerTitle}>Legal</Typography>
                <Link href="https://femexcel.com/terms-conditions/">Terms & Conditions</Link>
                <Link href="https://femexcel.com/privacy-policy/">Privacy Policy</Link>
                <Link href="https://femexcel.com/privacy-practices/">Consumer Health Privacy Practice</Link>
              </>
            )}
            {wtProduct === 'maleexcel' && (
              <>
                <Typography className={styles.footerTitle}>Learn more</Typography>
                <Link href="https://maleexcel.com/reviews/">Reviews</Link>
                <Link href="https://maleexcel.com/our-story/">Our Story</Link>
                <Link href="https://maleexcel.com/our-hrt-specialists/">Our Providers</Link>
                <Link href="https://maleexcel.com/contact/">Contact Us</Link>
                <Link href="https://maleexcel.com/blog/">Health Guide (Blog)</Link>
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={styles.box}>
            {wtProduct === 'maleexcel' && (
              <>
                <Typography className={styles.footerTitle}>Legal</Typography>
                <Link href="https://maleexcel.com/terms-conditions/">Terms & Conditions</Link>
                <Link href="https://maleexcel.com/privacy-policy/">Privacy Policy</Link>
                <Link href="https://maleexcel.com/privacy-practices/">Consumer Health Privacy Practice</Link>
              </>
            )}

            <Box className={styles.social} sx={{ m: isSmallScreen ? '0 auto' : '0' }}>
              <Box className={styles.socialIcons}>
                <Link
                  href={
                    wtProduct === 'femexcel'
                      ? 'https://www.facebook.com/femexcelle/'
                      : 'https://www.facebook.com/MaleExcel'
                  }
                >
                  <Facebook className={styles.socialIcon} />
                </Link>
                <Link
                  href={wtProduct === 'femexcel' ? 'https://x.com/maleexcelonline' : 'https://x.com/maleexcelonline'}
                >
                  <X color="#231f20" className={styles.socialIcon} />
                </Link>
                <Link
                  href={
                    wtProduct === 'femexcel'
                      ? 'https://www.instagram.com/femexcelle/'
                      : 'https://www.instagram.com/maleexcel/'
                  }
                >
                  <Instagram className={styles.socialIcon} />
                </Link>
                <Link
                  href={
                    wtProduct === 'femexcel'
                      ? 'https://www.linkedin.com/company/excelmedical'
                      : 'https://www.linkedin.com/company/male-excel/'
                  }
                >
                  <LinkedIn className={styles.socialIcon} />
                </Link>
              </Box>
            </Box>

            {wtProduct === 'maleexcel' && (
              <Link
                className={styles.legitScript}
                href="https://www.legitscript.com/websites/?checker_keywords=maleexcel.com"
                target="_blank"
              >
                <img
                  style={{ margin: isSmallScreen ? '0 auto' : '0' }}
                  src="https://static.legitscript.com/seals/3173220.png"
                  width="116"
                  height="100"
                  alt="LegitScript approved"
                />
              </Link>
            )}
          </Grid>
        </Grid>

        <Box sx={{ textAlign: 'center', p: 6 }} className={classes.maxWidthXl3}>
          <Typography className={styles.footerText}>
            The information provided on this website is for informational purposes and not
            a substitute for professional
            medical advice, diagnosis, or treatment. If you have questions or concerns about
            your health, please talk to
            your doctor.
          </Typography>
          {wtProduct === 'maleexcel' && (
            <Typography className={`${styles.footerText} ${classes.marginX} ${classes.marginY} ${classes.maxWidthXl4}`}>
              Prescription hormones from Male Excel require an online consultation with one of
              our licensed healthcare
              providers who will evaluate whether or not you are an appropriate candidate and
              review benefits and
              potential side effects with you prior to prescribing. Based on DEA and state laws,
              your testosterone
              treatment plan may require an in-person medical exam. Your Male Excel provider will
              be able to provide
              more details during your online consultations.{' '}
              <a title="See Important Safety Information" href="https://maleexcel.com/#">
                See Important Safety Information
              </a>
              .
            </Typography>
          )}
          {wtProduct === 'femexcel' && (
            <Typography className={`${styles.footerText} ${classes.marginY}`}>
              Prescription hormones from Fem Excel require an online consultation with one of our
              licensed healthcare
              providers who will evaluate whether or not you are an appropriate candidate and review
              benefits and
              potential side effects with you prior to prescribing. Based on DEA and state laws,
              your testosterone
              treatment plan may require an in-person medical exam. Your Fem Excel provider will
              be able to provide more
              details during your online consultations.
            </Typography>
          )}
          <hr className={styles.hr} />
          <Typography className={`${styles.footerText} ${classes.marginY}`}>
            &copy; Copyright {new Date().getFullYear()}, {wtProduct === 'maleexcel' ? 'Excel Medical' : 'Fem Excel'}.
            All Rights Reserved.
          </Typography>
          {wtProduct === 'maleexcel' && (
            <Typography className={styles.footerText}>
              Male Excel, Inc. is not connected or affiliated with Nelson Vergel,
              the EXCELMALE mark, EXCELMALE forum,
              or any associated products and services.
            </Typography>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default FooterMUI;
