/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { FormId } from 'interfaces/common-interfaces';
import QueryString from 'qs';
import TagManager from 'react-gtm-module-custom-domain';
import { RouteComponentProps } from 'react-router-dom';
import { AnswersResponse } from './answers-response';
import { DeadendResponse } from './deadend-response';
import { Prescription } from './get-current-scripts-response';
import {
  OrderPayload,
  NonLinearOrderPayload,
  Customer,
  LeadPayload,
  Scores,
  IdInfo,
  CheckIdInfo,
  CheckIdentityResp,
  BHRTPayload,
  MicroClinicsResp,
  KtCustomer,
  CommChangeInterface,
  CalendlySingleUse,
  CalendlyEvent,
  CheckLinkCHRTResponse,
} from './memo-service-interfaces';
import { Page, QuestionsResponse } from './questions-response';
import { TermsOfServiceResponse } from './terms-of-service-response';
import LandingResponse from './landing-response';
import PatientResponse from './patient-response';
import memo from './memo-axios-instances';

export async function createOrder(orderPayload: OrderPayload) {
  return memo
    .post('/memoForm/createOrder', {
      order: JSON.stringify(orderPayload),
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function getAnswers(
  landingId: string,
  formId: FormId
): Promise<AxiosResponse<AnswersResponse>> {
  return memo
    .get('/memoForm/answers', {
      params: {
        landing_id: landingId,
        formId,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function getAllergies(
  customerId: string
): Promise<AxiosResponse<AnswersResponse>> {
  return memo
    .get('/memoForm/allergies', {
      params: {
        customerId,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function getLead(landingId: string) {
  return memo
    .get('/memoForm/getLead', {
      params: {
        landing_id: landingId,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function getLeadFromCustomerId(customerId: string) {
  return memo
    .get('/memoForm/getLead', {
      params: {
        customerId,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function getPageQuestions(
  formId: FormId,
  pageIds?: Array<string>
): Promise<AxiosResponse<QuestionsResponse>> {
  return memo
    .get('/memoForm/pageQuestions', {
      params: {
        formId,
        pageIds,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}
export async function getSymptomQuestions(
  landing_id: string | null,
  customerId?: string | null
): Promise<
  AxiosResponse<{
    pages: Page[];
    patient?: PatientResponse;
    landing: LandingResponse;
  }>
> {
  return memo
    .get('/memoForm/symptomQuestions', {
      params: {
        landing_id,
        customerId,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function updateBillingInfo(customer: Customer, landingId: string) {
  return memo
    .post('/mipH2/billingInfo/save', {
      customer,
      landingId,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function saveLead(lead: LeadPayload, scores?: Scores | null) {
  TagManager.dataLayer({
    dataLayer: {
      landingId: lead.landing_id
    },
  });
  return memo
    .post('/memoForm/saveLead', {
      lead: JSON.stringify(lead),
      scores: scores ? JSON.stringify(scores) : undefined,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function checkCustQualifications(lead: LeadPayload) {
  return memo
    .post('/memoForm/checkCustQualifications', {
      lead: JSON.stringify(lead),
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function getMarkdown(data: string[]) {
  return memo
    .get('/memoForm/getMarkdown', {
      params: {
        names: JSON.stringify(data),
      },
    })
    .catch((err) => {
      console.error(err.response);
      throw err;
    });
}

type GetOrderConfig = {
  customerId: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  medOrders?: 'hrt' | 'ed';
  shouldRetry?: boolean;
};

export async function getOrder(config: GetOrderConfig) {
  const {
    customerId,
    firstName,
    lastName,
    phone,
    email,
    medOrders,
    shouldRetry,
  } = config;
  return memo
    .get('/mipH2/order', {
      maxRetries: shouldRetry ? 3 : 0,
      params: {
        customerId,
        firstName,
        lastName,
        phoneNumber: phone,
        emailAddress: email,
        medOrders,
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
      },
    })
    .catch((err) => {
      console.error(err.response);
      throw err;
    });
}

/**
 * getLastHRTMedOrder
 * @summary Return most recent hrt medication order. Needed for CHRT since konnektive
 * doesn't always update the monthly fee shipping address
 * @param {GetOrderConfig} config GetOrderConfig
 * @return {AxiosResponse} single record from kt_order
 */
export function getLastHRTMedOrder(config: Omit<GetOrderConfig, 'medOrders'>) {
  const { customerId, shouldRetry } = config;
  const getOrderConfig: GetOrderConfig = {
    customerId,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    email: undefined,
    shouldRetry,
  };
  return getOrder(getOrderConfig);
}

/**
 * getLastEDMedOrder
 * @summary Return most recent ed medication order.
 * @param {GetOrderConfig} config GetOrderConfig
 * @return {AxiosResponse} single record from kt_order
 */
export function getLastEdMedOrder(config: Omit<GetOrderConfig, 'medOrders'>) {
  const { customerId, firstName, lastName, phone, email, shouldRetry } = config;
  const getOrderConfig: GetOrderConfig = {
    customerId,
    firstName,
    lastName,
    phone,
    email,
    medOrders: 'ed',
    shouldRetry,
  };
  return getOrder(getOrderConfig);
}

export async function getLandingId(ktCustomerId: string) {
  return memo
    .get('/memoForm/ktCustomerLandingId', {
      params: { ktCustomerId },
    })
    .catch((err) => {
      console.error(err.response);
      throw err;
    });
}

export async function getHasId(
  ktCustomerId: string
): Promise<AxiosResponse<boolean>> {
  return memo
    .get(`/mipH2/getHasId/${ktCustomerId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
      },
    })
    .catch((err) => {
      console.error(err.response);
      throw err;
    });
}

export async function getCurrentScripts(
  ktCustomerId: string
): Promise<AxiosResponse<Prescription[]>> {
  return memo
    .get(`/mipH2/getCurrentScripts/${ktCustomerId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
      },
    })
    .catch((err) => {
      console.error(err.response);
      throw err;
    });
}

export async function updateKtOrderContinuous(
  ktCustomerId: string,
  medicationEffectiveness,
  isRestart,
  data: Record<string, string>
) {
  return memo
    .post('/memoForm/updateKtOrderContinuous', data, {
      params: {
        ktCustomerId,
        medicationEffectiveness,
        isRestart,
      },
    })
    .catch((err) => {
      console.error(err.response);
      throw err;
    });
}

export async function getKtOrderContinuous(
  ktCustomerId: string,
  isRestart: boolean
) {
  return memo
    .get(
      `/memoForm/getKtOrderContinuous?ktCustomerId=${ktCustomerId}&isRestart=${isRestart}`
    )
    .catch((err) => {
      console.error(err.response);
      throw err;
    });
}

export async function updateCEDStatus(landingId: string) {
  return memo
    .post('/memoForm/updateCEDStatus', {
      landing_id: landingId,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function updateCHRTStatus(
  landingId: string,
  leadLandingId: string
) {
  return memo
    .post('/memoForm/updateCHRTStatus', {
      landing_id: landingId,
      leadLandingId,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function updateFCHRTStatus(
  landingId: string,
  leadLandingId: string
) {
  return memo
    .post('/memoForm/updateFCHRTStatus', {
      landing_id: landingId,
      leadLandingId,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function newCHRTZeroDollarOrder(data) {
  return memo.post('/memoForm/newCHRTZeroDollarOrder', data).catch((err) => {
    console.error(err);
    throw err;
  });
}

export async function checkLinkCHRT(
  landingId: string
): Promise<AxiosResponse<CheckLinkCHRTResponse>> {
  return memo
    .post('/memoForm/checkLinkCHRT', {
      landing_id: landingId,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function checkLandingId(
  landingId: string,
  pathCode: string,
  formId: FormId
) {
  return memo
    .post('/memoForm/checkLandingId', {
      landing_id: landingId,
      pathCode,
      formId,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function updateTfLanding(landingId: string) {
  return memo
    .patch('/memoForm/updateTfLanding', {
      landing_id: landingId,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function createOrderNonLinear(
  orderPayload: NonLinearOrderPayload
) {
  return memo
    .post('/memoForm/createOrderNonLinear', {
      order: JSON.stringify(orderPayload),
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function validateKtCustomerId(ktCustomerId: string) {
  return memo
    .post('/memoForm/validateKtCustomerId', {
      params: { ktCustomerId },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function updateHRTInfoCreateOrder(order) {
  return memo.post('/memoForm/updateHRTInfoCreateOrder', order).catch((err) => {
    console.error(err);
    throw err;
  });
}

export async function checkIdentity(
  idInfo: IdInfo
): Promise<AxiosResponse<CheckIdentityResp>> {
  return memo
    .post(
      '/idVerification/checkIdentity',
      QueryString.stringify({ customer: JSON.stringify(idInfo) }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
        },
      }
    )
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function identityCheck(
  idInfo: CheckIdInfo
): Promise<AxiosResponse<CheckIdentityResp>> {
  return memo
    .post(
      '/idVerification/identityCheck',
      QueryString.stringify({ customer: JSON.stringify(idInfo) }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
        },
      }
    )
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function termsOfService(
  version: string
): Promise<AxiosResponse<TermsOfServiceResponse>> {
  return memo
    .get('/memoForm/termsOfService', {
      params: { version },
    })
    .catch((err: AxiosError) => {
      console.error(err);
      throw err;
    });
}

export async function deadEndAnswer(
  key: string
): Promise<AxiosResponse<DeadendResponse>> {
  return memo
    .get('/memoForm/deadEnd', {
      params: { deadEndAnswer: key },
    })
    .catch((err: AxiosError) => {
      console.error(err);
      throw err;
    });
}

export function patientTestResult(customerId: string) {
  return memo
    .post('/mipH2/patientTestResult', null, {
      params: { customerId },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function getMicroClinics(latLng: {
  lat?: number;
  lng?: number;
}): Promise<AxiosResponse<MicroClinicsResp[]>> {
  return memo
    .get(`/mipH2/microClinic?lat=${latLng.lat || ''}&lng=${latLng.lng || ''}`)
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function getMCPrevisit(customerId) {
  return memo.get(`/mipH2/mcPrevisit/${customerId}`).catch((err) => {
    console.error(err);
    throw err;
  });
}

export function postMCPrevisit(customerId, microClinicId) {
  return memo
    .post('/mipH2/mcPrevisit', {
      customerId,
      micro_clinicId: microClinicId,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function getStateEncounterTypes(state?: string) {
  return memo
    .get(`/memoForm/stateEncounterTypes/${state ?? ''}`)
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function getAppointment(
  appointmentId: string
): Promise<AxiosResponse<CalendlySingleUse>> {
  return memo
    .get('/calendly/getAppointment', {
      params: {
        appointmentId,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}
export function appointmentMade(
  appointmentId: string
): Promise<AxiosResponse<CalendlySingleUse>> {
  return memo
    .put('/calendly/appointmentMade', {
      appointmentId,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export type CalendlyEventType =
  | 'ed'
  | 'fhrtWelcome'
  | 'fhrtPreResults'
  | 'fhrtPostResults'
  | 'fhrtFollowUp'
  | 'clinic'
  | 'maleWelcome'
  | 'hrt'
  | 'hrtIntake'
  | 'hrtFollowUp15'
  | 'hrtFollowUp30'
  | 'hrtTransfer'
  | 'notOrderRelated';

export async function getCalendlyEvents(
  eventType: CalendlyEventType,
  uriLike?: string
): Promise<AxiosResponse<CalendlyEvent[]>> {
  return memo
    .get('/calendly/getEvents', {
      params: {
        eventType,
        uriLike: encodeURIComponent(uriLike ?? ''),
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function getCalendlyAvaliablity(
  id: number
): Promise<AxiosResponse<{ dates: string[] }>> {
  return memo.get(`/calendly/availableDates/${id}`).catch((err) => {
    console.error(err);
    throw err;
  });
}

export function appointmentMadeFromFlow(
  appointmentId: string
): Promise<AxiosResponse<CalendlySingleUse>> {
  return memo
    .put('/calendly/appointmentMadeFromFlow', {
      appointmentId,
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export async function addBHRTCustomer(data: BHRTPayload) {
  return memo.post('/memoForm/addBHRTCustomer', data).catch((err) => {
    console.error(err);
    throw err;
  });
}

export async function getUrlShortenerLink(hash: string) {
  return memo.get(`/memoForm/resolveURL?hash=${hash}`).catch((err) => {
    console.error(err);
    throw err;
  });
}
export function getKtCustomer(
  ktCustomerId: string
): Promise<AxiosResponse<KtCustomer>> {
  return memo
    .get(`/memoForm/ktCustomer/${ktCustomerId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function getPortalLink(
  email: string
): Promise<AxiosResponse<{ url: string }>> {
  return memo
    .patch(
      `/memoForm/portalLogin/${encodeURIComponent(email)}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
        },
      }
    )
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function verifyCommunicationChange(
  hash: string
): Promise<AxiosResponse<CommChangeInterface>> {
  return memo
    .patch(
      `/memoForm/commChangeConfirmation/${hash}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
        },
      }
    )
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function checkGenderByEmail(
  email: string
): Promise<AxiosResponse<{ gender: 'male' | 'female' | null }>> {
  return memo
    .get(`/memoForm/userEmailMF?email=${email}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function getReferralRecord(
  referralId: string
): Promise<AxiosResponse<{ patient: PatientResponse }>> {
  return memo
    .get(`/memoForm/referral/${referralId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

interface ReferralAnswerData {
  referralId: string;
  memory: string;
  libido: string;
  endurance: string;
  energy: string;
  sleep: string;
  testosteroneInterest: string;
  zeroDollarPending: boolean;
}

export function postReferralAnswers(
  referralAnswers: ReferralAnswerData
): Promise<AxiosResponse> {
  return memo
    .post('/memoForm/referral/', referralAnswers, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function checkGenderByPhone(
  phone: string,
  pageGender: 'male' | 'female'
): Promise<AxiosResponse<{ gender: 'male' | 'female' | null }>> {
  return memo
    .get(`/memoForm/userPhoneMF?phone=${phone}&gender=${pageGender}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_MEMO_BEARER_TOKEN}`,
      },
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}
export interface ProductResponse {
  /** Message from the server */
  message: string;
  /** Either a testkit or membership */
  type: 'product' | 'mpid';
  /**
  * The product id. Maps to the product ids from KNK.
  *
  * Allowing 'undefined' lets us keep the intellisense of specific ids
  * while also letting us pass in something generic in case we don't have an id.
  * type `string` would also work, but it collapses the values into the `string` type
  * and we lose intellisense. This also lets us keep all the arguments of getProductById
  * required, which is correct for the endpoint requirements. - Nanda 2024-01-11
  */
  externalId: '1099' | '1100' | '822' | '1124' | '1241' | '1422' | 'undefined';
  /** The campaign id. Maps to the campaign ids from KNK. */
  externalCampaignId: '116' | '151' | '145' | 'undefined';
  /** The name of the product */
  name: string;
  /** A description of the product, if any. */
  description: null | string;
  /** The brand of the product */
  brand: 'Maleexcel' | 'Femexcel';
  /** The category of the product. Male = 'hrt', Female = 'fhrt' */
  category: 'hrt' | 'fhrt';
  /** The full price of the product */
  price: number;
  /** The quantity. Locked to 1 since we don't sell multiples of the same product. */
  quantity: 1;
  /** The shipping cost */
  shipping: string;
  /** The price + shipping cost */
  total: string | '$99';
  /** The sale price, if any. */
  salePrice: string | null;
  /** A list of features the product includes. */
  packageIncludes: string[];
  /** A list of details the product includes. */
  includesDetail: string[];
  /** MPID of the product selected */
  mpId?: number;
}

interface ProductParams {
  /**
   * The product id. Maps to the product ids from KNK.
   *
   * Allowing 'undefined' lets us keep the intellisense of specific ids
   * while also letting us pass in something generic in case we don't have an id.
   * type `string` would also work, but it collapses the values into the `string` type
   * and we lose intellisense. This also lets us keep all the arguments of getProductById
   * required, which is correct for the endpoint requirements. - Nanda 2024-01-11
   */
  id: ProductResponse['externalId'];
  /** Either a testkit or membership */
  type: ProductResponse['type'];
  /** The category of the product. Male = 'hrt', Female = 'fhrt' */
  category: ProductResponse['category'];
}

/**
 * Get a product. If a product isn't found, it will return a default product
 * based on the `type` and `category`.
 * @param {object} params ProductParams
 * @returns {object} ProductResponse
 */
export async function getProduct(
  params: ProductParams
): Promise<AxiosResponse<ProductResponse>> {
  return memo.get('/memoForm/getProductInfo', { params }).catch((err) => {
    console.error(err);
    throw err;
  });
}

interface UpdateHRTPatientResponse {
  message: string;
}

interface UpdateHRTPatientData {
  mpid: string;
  landingId: string | undefined;
  location: RouteComponentProps['location'] | undefined;
}

export async function updateHRTPatient({
  mpid,
  landingId,
  location
}: UpdateHRTPatientData): Promise<AxiosResponse<UpdateHRTPatientResponse>> {
  return memo
    .post('/memoForm/updateHRTPatient', { mpid, landing_id: landingId, location })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export interface OrderSaveSOAPNoteResponse {
  status: boolean;
  message: string;
  url: string;
}

interface OrderSaveSOAPNoteParams {
  /** customerId */
  id: string;
  /** campaignId */
  campId?: string;
  /** days allowed between once a provider saves a SOAP note and when an order can be created */
  interval?: string;
}

export async function orderSaveSOAPNotes(
  params: OrderSaveSOAPNoteParams
): Promise<AxiosResponse<OrderSaveSOAPNoteResponse>> {
  return memo.post('/memoForm/orderSaveSOAPNotes', { params }).catch((err) => {
    console.error(err);
    throw err;
  });
}

type VerifyRecaptchaResponse = {
    name: string;
    event: {
        token: string;
        siteKey: string;
        'userAgent': string;
        'userIpAddress': string;
        'expectedAction': string;
        'hashedAccountId': string;
        'express': boolean;
        'requestedUri': string;
        'wafTokenAssessment': boolean;
        'ja3': string;
        'headers': string[];
        'firewallPolicyEvaluation': false;
        'fraudPrevention': string;
    },
    'riskAnalysis': {
        'score': number;
        'reasons': string[];
        'extendedVerdictReasons': string[];
        'challenge': string;
    },
    'tokenProperties': {
        'valid': boolean;
        'invalidReason': string;
        'hostname': string;
        'androidPackageName': string;
        'iosBundleId': string;
        'action': string;
        'createTime': string;
    },
    'accountDefenderAssessment': {
        'labels': string[];
    }
};

export async function verifyRecaptcha(
  action: string, email: string|null, phone:string|null, customerId: string|null
): Promise<VerifyRecaptchaResponse|void> {
  try {
    const recaptchaToken = await new Promise((res, rej) => {
      if (!grecaptcha) {
        rej(new Error('grecaptcha not available'));
        return;
      }
      const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
      if (!recaptchaKey) {
        rej(new Error('grecaptcha no key found'));
        return;
      }
      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise.execute(recaptchaKey, { action })
        .then((token) => {
          if (token) {
            res(token)
          } else {
            rej(new Error('grecaptcha no token returned'))
          }
        })
      });
    })
    let recaptchaResponse;
    if (recaptchaToken) {
      recaptchaResponse = await memo.post('/memoForm/verifyRecaptcha', { recaptchaToken, action, email, phone, customerId })
    }
    return recaptchaResponse?.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}
